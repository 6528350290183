<ngx-spinner></ngx-spinner>

<!-- FILTERS AND SORT BUTTON SECTION -->
<section id="fixed-filters-sortby-button" class="hideInMobile fixed-bottom">
	<div id="fixed-section-container" class="container-fluid mb-2">
		<div id="fixed-section-box" class="d-flex justify-content-center align-items-center">
			<div id="filters-button" class="d-block d-lg-none">
				<button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
					class="filter-btn btn-secondary dropdown-toggle pl-4 mb-3" (click)="openfilters = true"
					type="button">
					Filters
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
						class="bi bi-filter" viewBox="0 0 16 16">
						<path
							d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
					</svg>
				</button>
			</div>
			<div id="sort-button" class="mb-3">
				<button class="filter-btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
					data-toggle="dropdown" aria-controls="dropdownMenu" aria-label="Toggle Navigation"
					aria-expanded="false">
					<span class="mr-2"> Sort By </span>
					<i class="fas fa-sort"></i>
				</button>
				<div class="dropdown-menu" aria-labelledby="dropdownMenuButton" id="dropdownMenu">
					<a class="dropdown-item" (click)="Sort.LowToHigh()"> Price: Low to High </a>
					<a class="dropdown-item" (click)="Sort.HighToLow()"> Price: High to Low </a>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- FILTERS AND SORT BUTTON SECTION ENDS -->




<!-- FILTERS AND VEHICLE LISTING SECTION -->
<section class="container-fluid pt-2 pb-5 mob-height" [ngClass]="openfilters ? 'position-fixed' : ''">
	<div id="page-instruction-text" class="mx-2 mt-4 mb-2 hideMobile">
		<h5>
			Filter By
		</h5>
	</div>

	<p class="clearfix"></p>
	<div class="row">
		<!-- FILTERS SECTION -->
		<section id=" filters-container test" class="col-lg-3 col-xl-2">
			<div class="navbar-expand-lg pro-Fil">
				<div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="openfilters ? 'show' : ''">
					<div class="close-sidebar">
						<span (click)="openfilters = false">
							<i class="fas fa-times"></i>
						</span>
					</div>
					<aside class="sidebar w-100">
						<div id="page-instruction-text" class="mx-2 mt-4 mb-4 hideDesktop">
							<h5>
								Filter By
							</h5>
						</div>
						<div class="d-flex ml-2 mb-4">
							<button type="button" (click)="clearFilters(null)"
								class="custom-filled-button orange-button mr-4">
								Clear
							</button>
							<button type="button" (click)="getVehicleDetails(); openfilters=false"
								class="custom-filled-button green-button">
								Apply filter
							</button>

						</div>
						<div class="fil-itm" *ngIf="filters?.selections?.length > 0">
							<div class="btn-custom-btn btn btn-outline-dark" *ngFor="let obj of filters?.selections"
								(click)="clearFilters(obj)">
								<span class="close" aria-hidden="true">
									&times;
								</span>
								{{ obj['name'] }}
							</div>
						</div>
						<!-- THE SELECTED FILTERS LIST ENDS -->

						<!-- THE FILTER LIST - ACCORDION -->
						<div class="filter-list">
							<div class="accordion" id="accordionExample">
								<!-- OUTER CARD -->
								<div class="card accordian_Card mb-3"
									*ngFor="let filter_text of FILTERS_ORDER; index as filter_text_index">
									<!-- FILTER TITLE -->
									<div class="card-header" [attr.id]="'card_' + filter_text_index">
										<h2 class="mb-0">
											<button type="button"
												class="btn-custom-btn btn btn-link btn-block text-left"
												[ngClass]="filter_text_index > 0 ? 'collapsed' : ''"
												[attr.aria-controls]="'filter_' + filter_text_index"
												[attr.data-target]="'#filter_' + filter_text_index" aria-expanded="true"
												data-toggle="collapse">
												{{ formatter(filter_text.dp) | titlecase }}
											</button>
										</h2>
									</div>
									<!-- FILTER TITLE ENDS -->

									<!-- FILTER COLLAPSIBLE CARD - uncollapsed for first card-->
									<div class="collapse" data-parent="#accordionExample"
										[attr.aria-labelledby]="'card_' + filter_text_index"
										[attr.id]="'filter_' + filter_text_index"
										[ngClass]="filter_text_index == 0 ? 'show' : ''">
										<!-- IF RP IS A STRING -->
										<div class="card-body pt-0" *ngIf="!isArray(filter_text.rp)">
											<!-- Search Field -->
											<div class="filter-field mb-3"
												*ngIf="filter_text.dp != 'vehicle-type-preferences' ">
												<div class="input-group mb-3 ">
													<div class="input-group-prepend bg-white">
														<div class="input-group-text">
															<i class="fa fa-search" style="color:grey;"></i>
														</div>
													</div>
													<input class="form-control filter-search-area"
														(keyup)="searchIn(filter_text.rp, $event.target.value)"
														placeholder="Search" />
												</div>
											</div>
											<!-- Search Field Ends -->

											<!-- FILTER CHECKLIST AND FILTER CONTENT AREA -->
											<div class="checkbox-block">
												<!-- INPUT AND LABEL -->
												<div class="custom-control custom-checkbox"
													[ngClass]="filters?.copy['amenities'][14]?.chargeable == 'yes' && filter_index == 14 ? 'border-bottom border-secondary' : ''"
													*ngFor="let filter of filters.copy[filter_text.rp]; index as filter_index">
													<input type="checkbox" class="custom-control-input"
														[attr.id]="filter_text.dp + '_' + '_' + filter_index"
														[attr.name]="filter_text.dp + '_' + '_' + filter_index"
														(change)="filterSelection($event.target.checked, filter_text.rp, filter)"
														[checked]="isFilterAlreadySelected(filter_text.rp, filter)" />
													<label class="custom-control-label"
														[attr.for]="filter_text.dp + '_' + '_' + filter_index">
														<span class="checkbox-label-text">
															{{filter.name | titlecase }}
														</span>
														<i class="bi bi-currency-dollar text-orange"
															*ngIf="filter.chargeable == 'yes'"></i>
													</label>
												</div>
												<!-- INPUT AND LABEL ENDS -->

												<!-- SHOW MORE BUTTON -->
												<div [attr.id]="filter_text.dp"
													*ngIf="filters.vars[filter_text.rp] && (!isArray(filter_text?.rp) && filters?.original[filter_text.rp]?.length > min_length)">
													<span class="btn-custom-btn btn show-More" style="color:#ff7800"
														[attr.id]="'showMore_outer_' + filter_text_index"
														(click)="filters.vars['showMore_outer_' + filter_text.rp + '_' + filter_text_index] ? showMore('showMore_outer_' + filter_text.rp + '_' + filter_text_index, filter_text.rp, false) : showMore('showMore_outer_' + filter_text.rp + '_' + filter_text_index, filter_text.rp)">
														{{ filters.vars['showMore_outer_' + filter_text.rp + '_' +
														filter_text_index] ?
														'Show Less' : 'Show More (+' + this.min_length + ')' }}
														<i class="bi font-weight-bolder" [ngClass]="filters.vars['showMore_outer_' + filter_text.rp + '_' +
																		filter_text_index] ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
													</span>
												</div>
												<!-- SHOW MORE BUTTON ENDS -->
											</div>
											<!-- FILTER CHECKLIST AND FILTER CONTENT AREA ENDS -->
										</div>

										<!-- IF RP IS AN ARRAY -->
										<!-- INNER ACCORDION -->
										<div class="accordion pl-4" id="innerAccordionExample">
											<div class="card-body" *ngIf="isArray(filter_text.rp)">
												<!-- INNER CARD 1 -->
												<div class="card"
													*ngFor="let inner_filter_text of filter_text.rp; index as inner_filter_text_index">
													<!-- FILTER TITLE -->
													<div class="card-header"
														[attr.id]="'inner_card_' + inner_filter_text_index">
														<h2 class="mb-0">
															<button type="button"
																class="btn-custom-btn btn btn-link btn-block text-left"
																[ngClass]="inner_filter_text_index > 0 ? 'collapsed' : ''"
																[attr.aria-controls]="'inner_filter_' + filter_text.dp + '_' + inner_filter_text_index"
																[attr.data-target]="'#inner_filter_' + filter_text.dp + '_' + inner_filter_text_index"
																aria-expanded="true" data-toggle="collapse">
																{{ formatter(inner_filter_text.dp) | titlecase }}
															</button>
														</h2>
													</div>
													<!-- FILTER TITLE ENDS -->

													<!-- FILTER COLLAPSIBLE CARD - uncollapsed for first card-->
													<div class="collapse" data-parent="#innerAccordionExample"
														[attr.aria-labelledby]="'inner_card_' + inner_filter_text_index"
														[attr.id]="'inner_filter_' + filter_text.dp + '_' + inner_filter_text_index"
														[ngClass]="inner_filter_text_index == 0 ? 'show' : ''">
														<!-- IF RP HAS A STRING VALUE -->
														<div class="card-body pt-0"
															*ngIf="!isArray(inner_filter_text.rp)">
															<!-- Search Field -->
															<div class="filter-field mb-3">
																<div class="input-group mb-3 ">
																	<div class="input-group-prepend bg-white">
																		<div class="input-group-text">
																			<i class="fa fa-search"
																				style="color:grey;"></i>
																		</div>
																	</div>
																	<input class="form-control filter-search-area"
																		(keyup)="searchIn(inner_filter_text.rp, $event.target.value)"
																		placeholder="Search" />
																	<!--  for {{ halfText(formatter(inner_filter_text.dp)) | titlecase }} -->
																</div>
															</div>
															<!-- Search Field Ends -->

															<!-- FILTER CHECKLIST AND FILTER CONTENT AREA -->
															<div class="checkbox-block">
																<!-- INPUT AND LABEL -->
																<div class="custom-control custom-checkbox"
																	*ngFor="let inner_filter of filters?.copy[inner_filter_text.rp]; index as inner_filter_index">
																	<input type="checkbox" class="custom-control-input"
																		[attr.id]="inner_filter_text.dp + '_' + '_' + inner_filter_index"
																		[attr.name]="inner_filter_text.dp + '_' + '_' + inner_filter_index"
																		(change)="filterSelection($event.target.checked, inner_filter_text.rp, inner_filter)"
																		[checked]="isFilterAlreadySelected(inner_filter_text.rp, inner_filter)" />
																	<label class="custom-control-label"
																		[attr.for]="inner_filter_text.dp + '_' + '_' + inner_filter_index">
																		<span *ngIf="inner_filter.name != 'LGBTQIA+'">
																			{{ formatter(inner_filter.name) | titlecase
																			}}
																		</span>
																		<span *ngIf="inner_filter.name == 'LGBTQIA+'">
																			<span class="checkbox-label-text">
																				{{ inner_filter.name }}
																			</span>
																		</span>
																	</label>
																</div>
																<!-- INPUT AND LABEL ENDS -->

																<!-- SHOW MORE BUTTON -->
																<div
																	*ngIf="filters.vars[inner_filter_text.rp] && (!isArray(inner_filter_text.rp) && filters?.original[inner_filter_text.rp]?.length > min_length)">
																	<span class="btn-custom-btn btn show-More"
																		style="color:#ff7800"
																		[attr.id]="'showMore_inner' + inner_filter_text_index"
																		(click)="filters.vars['showMore_inner_' + inner_filter_text.rp + '_' +
																		inner_filter_text_index] ? showMore('showMore_inner_' + inner_filter_text.rp + '_' + inner_filter_text_index, inner_filter_text.rp, false) : showMore('showMore_inner_' + inner_filter_text.rp + '_' + inner_filter_text_index, inner_filter_text.rp)">
																		{{ filters.vars['showMore_inner_' +
																		inner_filter_text.rp + '_' +
																		inner_filter_text_index] ? 'Show Less' :
																		'Show More (+' + this.min_length + ')' }}
																		<i class="bi font-weight-bolder" [ngClass]="filters.vars['showMore_inner_' + inner_filter_text.rp + '_' +
																		inner_filter_text_index] ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
																	</span>
																</div>
																<!-- SHOW MORE BUTTON ENDS -->
															</div>
															<!-- FILTER CHECKLIST AND FILTER CONTENT AREA ENDS -->
														</div>
													</div>
												</div>
												<!-- INNER CARD 1 ENDS -->
											</div>
										</div>
										<!-- INNER ACCORDION ENDS -->
									</div>
								</div>
								<!-- OUTER CARD -->
							</div>
						</div>
						<div class="d-flex ml-2 mb-4">
							<button type="button" (click)="clearFilters(null)"
								class="custom-filled-button orange-button mr-4">
								Clear
							</button>
							<button type="button" (click)="getVehicleDetails(); openfilters=false"
								class="custom-filled-button green-button">
								Apply filter
							</button>

						</div>

						<!-- FILTER LIST ENDS - ACCORDION-->
					</aside>
				</div>
			</div>
		</section>
		<!-- FILTERS SECTION ENDS -->

		<ng-container *ngIf="vehicleDetails.length > 0; then vehicleListing"></ng-container>

		<!-- VEHICLE LISTING SECTION -->
		<ng-template #vehicleListing>
			<section id="vehicle-listing-container" class="hideInMobile col-md-12 col-lg-9 col-xl-10"
				[ngStyle]="openfilters ? {'z-index': -1, 'position': 'relative'} : ''">
				<div class="select_vehicle_div">
					<div class="row d-flex justify-content-between align-items-center">
						<div class="col-md-6">
							<h5 style="color:#fff;font-size: 22px;">
								Select the vehicle that meets your requirements.
							</h5>
							<span style="color:#fff;font-size: 14px;">
								<span style="color:#FEB573;">Use Filter / </span>Sort for more specific choices to match
								your
								search for rates.
							</span>
						</div>
						<div class="col-md-6 d-flex justify-content-end">
							<button type="button" class="custom-filled-button green-button" (click)="backButton()">
								<i class="bi bi-arrow-left mx-1"></i>
								Back to Master vehicle
							</button>
						</div>
					</div>
				</div>
				<div class="vehicle-grid-card">
					<div id="vehicle-listing-card_{{ vehinfo_index }}" class="card vehicle-listing-card"
						*ngFor="let vehinfo of vehicleDetails; index as vehinfo_index"
						(click)="innerWidth < 1023 ? viewDetails(vehinfo) : ''">
						<!-- DIV: AFFILIATE INFO -->
						<div id="affiliate-info"
							*ngIf="currentUser != null && currentUser?.roleName == 'admin' && (vehinfo?.affiliate_name || vehinfo?.affiliate_company)">
							<button class="link" *ngIf="vehinfo?.readMore">
								<p class="text-left text-secondary m-0 my-md-2" style="color: #007fee !important;">
									<span (click)="editAffiliateAccount(vehinfo)">
										{{vehinfo?.affiliate_company.slice(0,7) }}
										<span *ngIf="vehinfo?.badge_city != 'N/A'"
											style="text-transform: uppercase;">({{vehinfo?.badge_city}})</span>
										{{'/ '+vehinfo?.affiliate_name.slice(0,7) }}
									</span>
									<span (click)="handleReadMore(vehinfo , false)" style="color: black;">...more</span>
								</p>
							</button>
							<button class="link" *ngIf="!vehinfo?.readMore">
								<p class="text-left text-secondary m-0 my-md-2" style="color: #007fee !important;">
									<span (click)="editAffiliateAccount(vehinfo)">
										{{vehinfo?.affiliate_company }}
										<span *ngIf="vehinfo?.badge_city != 'N/A'"
											style="text-transform: uppercase;">({{vehinfo?.badge_city}})</span>
										{{'/ '+vehinfo?.affiliate_name }}
										<!-- {{'/ '+vehinfo?.affiliate_phone}} -->
									</span>|<br>
									<span>
										<p data-target="#sendEmailModal" style="white-space: pre" data-toggle="modal"
											(click)="openModal(vehinfo)" id="box">
											<span>{{vehinfo?.affiliate_phone}}</span>
										</p>
										<!-- <a href="tel:{{vehinfo?.affiliate_phone}}">
										{{vehinfo?.affiliate_phone}}
									</a> -->
									</span>
									<!-- <a href="tel:+918219479708">+918219479708</a> -->
									<span (click)="handleReadMore(vehinfo , true)" style="color: black;">...less</span>
								</p>
							</button>

							<!-- <ng-template #readMoreTemp>
							<p class="text-left text-secondary m-0 my-md-2" [class.show]="!show" >
								{{ vehinfo.affiliate_company.length > 10 ? vehinfo.affiliate_company.slice(0,5)+"..." : vehinfo.affiliate_company }}
								{{vehinfo.affiliate_name.length > 10 ?  '/ '+vehinfo.affiliate_name.slice(0,5)+'...' : '/ '+vehinfo.affiliate_name  }}
							</p>
							<p class="text-left text-secondary m-0 my-md-2" [class.show]="show">
								{{ vehinfo.affiliate_company }}
								{{'/ '+vehinfo.affiliate_name}}
							</p>
							<button (click)="show+index = !show+index">{{ show ? 'Show less': 'Show More' }}</button>
						</ng-template> -->
						</div>
						<div *ngIf="vehinfo?.is_master_vehicle==true || vehinfo?.affiliate_id==''">
							<p style="padding: 4px; font-weight: bolder;">Master vehicle</p>
						</div>
						<!-- AFFILIATE INFO ENDS -->

						<!-- DIV: VEHICLE DETAILS -->
						<div id="vehicle-details"
							class="details d-flex flex-row flex-wrap justify-content-left align-items-center">
							<!-- VEHICLE: IMAGE -->
							<div id="vehicle-image_{{ vehinfo_index }}"
								class="imageStyle bg-white d-flex justify-content-center">
								<img id="V_I_{{ vehinfo_index }}" [src]="vehinfo.vehicle_images[0]" class="img-fluid" />
							</div>
							<!-- IMAGE ENDS -->

							<!-- VEHICLE: INFORMATION -->
							<div id="vehinfo_{{ vehinfo_index }}" class="vehicle-info">
								<!-- VEHICLE: PRICE -->
								<div class="d-flex price mb-2" *ngIf="vehinfo?.number_of_vehicles <= 1">
									<div class="col-md-6 p-0" style="min-width: fit-content;">
										{{ formatter(getKeyName()) | titlecase }}
									</div>
									<div class="col-md-6" style="min-width: fit-content;">
										<span style="font-size: 20px;">
											{{currencySymbol}}
											{{ vehinfo['rate_breakdown_' + getKeyName()]?.grand_total != undefined ?
											vehinfo['rate_breakdown_' + getKeyName()]?.grand_total : '0.00' }}
										</span>
									</div>
								</div>
								<div class="d-flex price">
									<!-- <div class="col-md-12 p-0" style="min-width: fit-content;">
										Vehicle Type
									</div> -->
									<div class="col-md-12 p-0 mb-4">
										<span class="font-weight-bold"
											style="font-size :1.2rem ;">{{vehinfo?.name}}</span>
									</div>
								</div>
								<div class="d-flex price mb-2" *ngIf="vehinfo?.number_of_vehicles > 1">
									<div class="col-md-8 p-0" style="min-width: fit-content;">
										Estimated One-Way
									</div>
									<div class="col-md-4" style="min-width: fit-content;">
										<span class="font-weight-bold" style="font-size: 20px;">{{currencySymbol}}
											{{vehinfo?.estimated_price_one_way}}</span>
									</div>
								</div>
								<div class="d-flex price mb-2" *ngIf="vehinfo?.number_of_vehicles > 1">
									<div class="col-md-8 p-0" style="min-width: fit-content;">
										Estimated Round-Trip
									</div>
									<div class="col-md-4" style="min-width: fit-content;">
										<span class="font-weight-bold" style="font-size: 20px;">{{currencySymbol}}
											{{vehinfo?.estimated_price_round_trip}}</span>
									</div>
								</div>
								<div class="col-md-12 mb-3 p-0">
									<span class="text-rate">
										All Inclusive Rate<sup class="sup-red">*</sup>
									</span>
								</div>
								<div id="icons" class="passnger-luggage-div mb-3 p-2">
									<div class="icon-container">
										<img alt="" src="assets/images/select_vehicle/pass.png"
											style="margin-right: 0.50rem;" />
										<span>Passengers</span>
										<span class="font-weight-bolder mx-2">{{ vehinfo?.passenger }}</span>
									</div>
									<div class="icon-container">
										<img alt="" src="assets/images/select_vehicle/lug.png"
											style="margin-right: 0.50rem;" />
										<span>Luggages</span>
										<span class="font-weight-bolder mx-2">{{ vehinfo?.luggage }}</span>
									</div>
								</div>
								<div class="p-3">
									<!-- <div class="d-flex price">
										<div class="col-md-12 p-0" style="min-width: fit-content;">
											Vehicle Type
										</div>
										<div class="col-md-12 p-0 mb-2">
											<span class="font-weight-bold"
												style="font-size :1.2rem ;">{{vehinfo?.name}}</span>
										</div>
									</div> -->

									<div class="d-flex price">
										<div class="col-md-8 p-0" style="min-width: fit-content;">
											Number of Vehicles
										</div>
										<div class="col-md-4" style="min-width: fit-content;">
											<span class="font-weight-bold">{{vehinfo?.number_of_vehicles}}</span>
										</div>
									</div>

									<div class="d-flex price"
										*ngIf="vehinfo?.vehicle_details.year && !vehinfo?.is_master_vehicle">
										<div class="col-md-8 p-0" style="min-width: fit-content;">Year</div>
										<div class="col-md-4" style="min-width: fit-content;">
											<span class="font-weight-bold">
												{{vehinfo?.vehicle_details.year}}
											</span>
										</div>
									</div>
									<div class="d-flex price"
										*ngIf="vehinfo?.vehicle_details.make  && !vehinfo?.is_master_vehicle">
										<div class="col-md-8 p-0" style="min-width: fit-content;">Make</div>
										<div class="col-md-4" style="min-width: fit-content;">
											<span class="font-weight-bold">{{vehinfo?.vehicle_details.make}}</span>
										</div>
									</div>
									<div class="d-flex price"
										*ngIf="vehinfo?.vehicle_details.model  && !vehinfo?.is_master_vehicle">
										<div class="col-md-8 p-0" style="min-width: fit-content;">Model</div>
										<div class="col-md-4" style="min-width: fit-content;">
											<span class="font-weight-bold">{{vehinfo?.vehicle_details.model}}</span>
										</div>
									</div>
								</div>
								<span class="d-flex cancel-policy mb-3" *ngIf="!vehinfo?.is_master_vehicle">
									<i class="bi bi-exclamation-circle-fill" style="color: red;"></i>
									&nbsp; Cancellation Policy: <span
										style="font-weight: 500;">{{convertHrIntoDays(vehinfo?.cancellation_policy)}}
									</span>
								</span>
							</div>
							<!-- INFORMATION ENDS -->
						</div>
						<!-- VEHICLE DETAILS ENDS -->

						<!-- DIV: PREVIEW BUTTON -->
						<div class="d-flex justify-content-between mb-1 align-items-center">
							<button type="button" (click)="viewDetails(vehinfo)"
								class="custom-filled-button orange-button" style="height: 40px !important;">
								Booking Details
							</button>
							<button type="button" (click)="bookNow(vehinfo)"
								class="custom-filled-button green-button rounded-pill"
								style="font-size: 1.1rem !important;padding: 0.495rem !important;">
								Book Now
							</button>
						</div>
						<!-- <div id="vehicle-preview-button" style="display: flex;justify-content: space-around;">
							<button class="custom-filled-button orange-button mx-2" (click)="viewDetails(vehinfo)"
								style="height: 40px; margin-top: 10px;">
								Vehicle Details
							</button>
							<button class="custom-filled-button text-dark rounded-pill" (click)="bookNow(vehinfo)">
								Book Now
							</button>

						</div> -->
						<!-- PREVIEW BUTTON ENDS -->
					</div>
				</div>
			</section>
		</ng-template>

		<div id="vehicle-listing-container" class="hideInDesktop col-md-12 col-lg-9 col-xl-10"
			[ngStyle]="openfilters ? {'z-index': -1, 'position': 'relative'} : ''">
			<!-- title box -->
			<div class="select_vehicle_div_mobile">
				<button type="button" class="back_button_mobile mb-2" (click)="backButton()">
					<i class="bi bi-arrow-left mx-1"></i>
					Back to Master vehicle
				</button>
				<div class="row d-flex justify-content-between align-items-center">
					<div class="col-md-12" style="display: flex;flex-direction: column;">
						<span style="color:#fff;font-size: 16px;font-weight: 500;">
							Select the vehicle that meets your requirements
						</span>
						<span style="color:#fff;font-size: 13px;font-weight: 500;">
							Use <span style="color:#FEB573;font-weight: 500;">Filter / Sort</span> for more specific
							choices to match
							your
							search for rates.
						</span>
					</div>

				</div>
			</div>
			<div class="sort_filter_div_mobile mb-3">
				<div>
					<!-- <span style="font-weight: 500;margin-left: 10px;">
					Save this search
				</span> -->
				</div>
				<div class="d-flex justify-content-end ">
					<span>
						<a href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true"
							aria-expanded="false" style="text-decoration: none;font-weight: 500;color: blue;margin-right: 5px;">
							<i class="bi bi-caret-down"></i> Sort
						</a>
						<div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
							aria-labelledby="navbarDropdownUserImage">
							<a class="dropdown-item" (click)="Sort.LowToHigh()"> Price: Low to High </a>
							<a class="dropdown-item" (click)="Sort.HighToLow()"> Price: High to Low </a>
						</div>
					</span>
					<span>
						|
					</span>
					<span style="font-weight: 500;margin: 0px 10px 0px 5px;color: blue;" (click)="openfilters = true">
						<i class="bi bi-filter"></i> Filter
					</span>
				</div>
			</div>

			<div class="vehicle-grid-card" style="grid-gap: 15px !important;">
				<div id="vehicle-listing-card_{{ vehinfo_index }}" class="card vehicle-listing-card"
					*ngFor="let vehinfo of vehicleDetails; index as vehinfo_index"
					(click)="innerWidth < 1023 ? viewDetails(vehinfo) : ''">
					<!-- DIV: AFFILIATE INFO -->
					<div id="affiliate-info"
						*ngIf="currentUser != null && currentUser?.roleName == 'admin' && (vehinfo?.affiliate_name || vehinfo?.affiliate_company)">
						<!-- <button class="link" *ngIf="vehinfo?.readMore">
							<p class="text-left text-secondary m-0 my-md-2" style="color: #007fee !important;">
								<span (click)="editAffiliateAccount(vehinfo)">
									{{vehinfo?.affiliate_company.slice(0,7) }}
									<span *ngIf="vehinfo?.badge_city != 'N/A'"
										style="text-transform: uppercase;">({{vehinfo?.badge_city}})</span>
									{{'/ '+vehinfo?.affiliate_name.slice(0,7) }}
								</span>
								<span (click)="handleReadMore(vehinfo , false)" style="color: black;">...more</span>
							</p>
						</button> -->
						<button class="link">
							<p class="text-left text-secondary m-0 my-md-2" style="color: #007fee !important;">
								<span (click)="editAffiliateAccount(vehinfo)">
									{{vehinfo?.affiliate_company }}
									<span *ngIf="vehinfo?.badge_city != 'N/A'"
										style="text-transform: uppercase;">({{vehinfo?.badge_city}})</span>
									{{'/ '+vehinfo?.affiliate_name }}
									<!-- {{'/ '+vehinfo?.affiliate_phone}} -->
								</span>|<br>
								<span>
									<p data-target="#sendEmailModal" style="white-space: pre" data-toggle="modal"
										(click)="openModal(vehinfo)" id="box">
										<span>{{vehinfo?.affiliate_phone}}</span>
									</p>
								</span>
								<!-- <span (click)="handleReadMore(vehinfo , true)" style="color: black;">...less</span> -->
							</p>
						</button>
					</div>
					<div *ngIf="vehinfo?.is_master_vehicle==true || vehinfo?.affiliate_id==''">
						<p style="padding: 4px; font-weight: bolder;">Master vehicle</p>
					</div>
					<!-- AFFILIATE INFO ENDS -->

					<div id="vehicle-image_{{ vehinfo_index }}" class="bg-white d-flex mt-2 flex-column">
						<div class="d-flex mb-2">
							<div class="col-sm-3 imageStyle-mobile">
								<img id="V_I_{{ vehinfo_index }}" [src]="vehinfo.vehicle_images[0]" />
							</div>

							<div id="vehinfo_{{ vehinfo_index }}" class="col-md-9" style="padding: 0 !important;">
								<div class="d-flex flex-column">
									<p id="vehicle-name" class="vehicle-name-text-mobile">
										{{vehinfo?.name}}
									</p>
									<p class="font-weight-bolder mb-1" style="font-size: 0.8rem;"
										*ngIf="vehinfo?.number_of_vehicles <= 1">
										<span style="color: #c94505;font-weight: 500;">
											{{ formatter(getKeyName()) | titlecase }}&nbsp;
										</span>
										{{currencySymbol}}
										{{ vehinfo['rate_breakdown_' + getKeyName()]?.grand_total != undefined ?
										vehinfo['rate_breakdown_' + getKeyName()]?.grand_total : '0.00' }}
									</p>
									<p class="font-weight-bolder mb-2" style="font-size: 0.8rem;"
										*ngIf="vehinfo?.number_of_vehicles > 1">
										<span style="color: #c94505;font-weight: 500;">
											Estimated One-Way&nbsp;
										</span>
										{{currencySymbol}}
										{{vehinfo?.estimated_price_one_way}}
									</p>
									<p class="font-weight-bolder mb-1" style="font-size: 0.8rem;"
										*ngIf="vehinfo?.number_of_vehicles > 1">
										<span style="color: #c94505;font-weight: 500;">
											Estimated Round-Trip
										</span>
										{{currencySymbol}}
										{{vehinfo?.estimated_price_round_trip}}
									</p>
									<p id="vehicle-name" class="text-rate mb-2">
										All Inclusive Rate<sup class="sup-red">*</sup>
									</p>
									<div id="icons" class="d-flex mb-2" style="gap:8px;">
										<div class="icon-container-mobile">
											<img alt="" src="assets/images/select_vehicle/pass.png"
												style="padding: 3px;" />
												<span style="font-size: 0.8rem;margin-left: 0.1rem;font-weight: 500;">Pax</span>
											<span class="font-weight-bolder" style="font-size: 0.9rem;padding: 3px;">{{
												vehinfo?.passenger
												}}</span>
										</div>
										<div class="icon-container-mobile">
											<img alt="" src="assets/images/select_vehicle/lug.png"
												style="padding: 3px;" />
												<span style="font-size: 0.8rem;margin-left: 0.1rem;font-weight: 500;">Luggage</span>
											<span class="font-weight-bolder" style="font-size: 0.9rem;padding: 3px;">{{
												vehinfo?.luggage
												}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div style="padding: 0px 14px 14px 14px;">
							<div class="vehicle-details-data-mobile">
								<div class="d-flex justify-content-between" style="max-height: 25px;">
									<p>
										Year&nbsp;<span style="font-weight: 500;">{{vehinfo?.vehicle_details?.year}}</span>
									</p>
									<p>
										Make&nbsp;<span style="font-weight: 500;">{{vehinfo?.vehicle_details?.make}}</span>
									</p>
									<p>
										Model&nbsp;<span style="font-weight: 500;">{{vehinfo?.vehicle_details?.model}}</span>
									</p>
								</div>
							</div>
						</div>
						<div class="d-flex justify-content-between align-items-center mb-2"
							style="max-height: 25px;padding: 2px 16px 2px 16px;" *ngIf="!vehinfo?.is_master_vehicle">
							<p style="font-size: 0.9rem;">
								<i class="bi bi-exclamation-circle-fill" style="color: red;"></i>
								&nbsp; Cancellation Policy: &nbsp;<span style="font-weight: 500;">{{convertHrIntoDays(vehinfo?.cancellation_policy)}}</span>
							</p>
						</div>
						<div class="d-flex justify-content-between align-items-center mb-2"
							style="padding: 2px 16px 2px 16px;">
							<button type="button" (click)="viewDetails(vehinfo)"
								class="custom-filled-button orange-button" style="font-size: 0.8rem !important;">
								Booking Details
							</button>
							<button type="button" (click)="bookNow(vehinfo)" class="custom-filled-button green-button"
								style="font-size: 0.8rem !important;">
								Book Now
							</button>
						</div>
					</div>
				</div>
			</div>

		</div>
		<!-- VEHICLE LISTING SECTION ENDS -->

		<!-- MASTER VEHICLE TYPES SECTION -->
		<!-- <ng-template #MasterVehicleListing>
			<div class="col-md-12 col-lg-9" [ngStyle]="openfilters ? {'z-index': -1, 'position': 'relative'} : ''"
				*ngIf="vehicleDetails.length == 0 || master_vehicles.length == 0">
				<p style="font-size: 1rem; font-weight: 500; color: #e63232"
					*ngIf="(vehicleDetails.length == 0 && this.filters.selections.length > 0) || master_vehicles.length == 0">
					{{ no_vehicle_msg }}
				</p>
				<div id="master-vehicle-box-container" class="row" *ngIf="master_vehicles.length > 0">
					<div id="master-vehicle-box" class="col-md-3 mb-5 d-flex flex-nowrap flex-md-wrap cursor-pointer"
						style="gap: 10px" *ngFor="let vehicle_obj of master_vehicles; index as vehicle_obj_index"
						(click)="selectCategory(vehicle_obj?.name)">
						<div id="vehicle-image" class="bg-white imageStyle">
							<img [src]="vehicle_obj.cat_img" class="img-fluid"
								[attr.id]="'vehicle_' + vehicle_obj_index" />
						</div>
						<div id="vehicle-information" class="m-0">
							<p id="vehicle-name">
								{{ vehicle_obj?.name }}
							</p>
							<p id="vehicle-price" class="font-weight-bolder" style="font-size: 1.1rem"
								*ngIf="('rate_breakdown_' + getKeyName()) != undefined || ('rate_breakdown_' + getKeyName()).length <= 0">
								<span style="color: var(--orange);">
									From
								</span>
								${{ vehicle_obj['rate_breakdown_' + getKeyName()]?.grand_total != undefined ?
								vehicle_obj['rate_breakdown_' + getKeyName()]?.grand_total : '0.00' }}
							</p>
							<div id="icons">
								<img alt="" src="assets/images/select_vehicle/pass.png" class="mx-1"
									style="width: 13px" />
								<span class="marginText mx-2">{{ vehicle_obj?.passenger }}</span>
								<img alt="" src="assets/images/select_vehicle/lug.png" class="mx-1" />
								<span class="marginText2 mx-2">{{ vehicle_obj?.luggage }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template> -->
		<!-- MASTER VEHICLE TYPES SECTION ENDS -->
	</div>
</section>









<!-- --------------------------------------- MODALS -------------------------------------- -->

<div aria-hidden="true" aria-labelledby="filtersModal" class="modal" id="filtersModal" tab-index="-1">
	<div class="modal-dialog modal-dialog-scrollable modal-lg ">
		<div class=" modal-content">
			<div class="modal-header d-block" style="font-family: 'pt_serifregular'">
				<h4 class="modal-title">
					{{ formatter(vehicle_selected?.selection_button) | titlecase }}
				</h4>
				<div class="modal-body">
					<div>
						<div *ngIf="vehicle_selected?.selection_button == 'amenities'">
							<table class="table">
								<thead>
									<tr>
										<th> Name </th>
										<th> Chargeable/Non-Chargeable </th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let amenity of vehicle_selected?.amenities">
										<td> <strong> {{ amenity.name }} </strong></td>
										<td> {{ amenity.chargeable == 'yes' ? 'Chargeable' : 'Non-Chargeable' }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div *ngIf="vehicle_selected?.selection_button == 'driver_info'">
						<div class="d-flex align-items-center"
							*ngIf="vehicle_selected?.driverInformation; else noinfotext">
							<div class="align-self-center justify-self-center">
								<img [src]="vehicle_selected?.driverInformation?.imageUrl" class="driver_image" />
							</div>
							<div id="driver-info" style="width: 100%" class="align-self-start">
								<div class="row mx-3 my-2" *ngFor="let item of modal_driver_info_labels">
									<div class="col-md-5">
										{{ formatter(item) | titlecase }}
									</div>
									<div class="col-md-auto">
										<strong *ngIf="item != 'starRating'">
											{{ vehicle_selected.driverInformation[item] != '' ?
											formatter(vehicle_selected.driverInformation[item]) : 'No' }}
										</strong>
										<strong *ngIf="item == 'starRating'">
											{{ vehicle_selected.driverInformation[item] != '' ?
											formatter(vehicle_selected.driverInformation[item]) : 'Not Rated' }}
										</strong>
									</div>
								</div>
							</div>
						</div>
						<ng-template #noinfotext>
							<p class="text-danger">
								No Information available
							</p>
						</ng-template>
					</div>
				</div>
				<div class="modal-footer">
					<div class="btn-Area">
						<button class="btn-custom-btn btn btn-secondary px-5" data-dismiss="modal" type="button">
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>



<div class="modal fade show" id="myModal" role="dialog">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close closeButton" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
			<div class="modal-header pb-0">
				<h3 class="modal-title mb-0">Edit Passengers / Luggages</h3>
			</div>
			<hr>
			<div class="modal-body">
				<div class="row">
					<!-- Number of Passengers -->
					<div class="col-6 col-md-6">
						<label>
							# of Passengers
						</label>
						<div>
							<ul class="number passengers_qty">
								<li class="user_grp"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
										fill="#fff" height="22px">
										<path
											d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z" />
									</svg>
								</li>
								<li>
									<div class="input-group">
										<div class="input-group-prepend">
											<button style="min-width: 2.5rem"
												class="btn-custom-btn btn btn-decrement btn-outline-secondary"
												type="button" (click)="change('d','p')">
												<strong>-</strong>
											</button>
										</div>
										<input type="number" [value]="quotebot_form.no_of_passenger" min="0" max="1000"
											step="1" inputmode="decimal" style="text-align: center" class="form-control"
											placeholder="">
										<div class="input-group-append">
											<button style="min-width: 2.5rem"
												class="btn-custom-btn btn btn-increment btn-outline-secondary"
												type="button" (click)="change('i','p')">
												<strong>+</strong>
											</button>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>

					<!-- Number of Luggage -->
					<div class="col-6 col-md-6">
						<label># of Luggage</label>
						<div>
							<ul class="number passengers_qty">
								<li class="user_grp"><svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd"
										stroke-linejoin="round" stroke-miterlimit="1.414" clip-rule="evenodd"
										viewBox="0 0 65 64" fill="#fff" height="22px">
										<rect width="64" height="64" x=".749" fill="none" />
										<path fill-rule="nonzero"
											d="M24.784,11.883l0,-2.92c0.01,-1.551 1.341,-2.959 2.954,-2.989c4.016,-0.026 8.032,-0.026 12.048,0c1.549,0.029 2.943,1.364 2.954,2.989l0,2.944c4.998,0.016 9.996,0.037 14.994,0.052c2.578,0.024 4.932,2.258 4.957,4.957c0.035,12.002 0.111,24.004 0,36.005c-0.04,2.551 -2.246,4.883 -4.926,4.925c-16.001,0.149 -32.005,0.149 -48.007,0c-2.561,-0.04 -4.883,-2.229 -4.925,-4.925c-0.112,-12.012 -0.112,-24.025 0,-36.036c0.04,-2.548 2.238,-4.884 4.925,-4.926c5.009,-0.046 10.017,-0.069 15.026,-0.076Zm32.938,2.071c-5.268,-0.016 -42.618,0 -47.9,0c-1.552,0.005 -2.979,1.335 -2.994,2.974c-0.037,11.989 -0.112,23.979 0,35.968c0.024,1.545 1.364,2.941 2.974,2.956c15.979,0.05 31.96,0.148 47.938,-0.001c1.545,-0.024 2.941,-1.393 2.956,-2.973c0.035,-11.99 0.111,-23.98 0,-35.968c-0.024,-1.545 -1.355,-2.941 -2.974,-2.956Zm-44.909,5.985l-0.997,0c-0.577,-0.037 -0.697,-0.209 -0.864,-0.498c-0.343,-0.594 0.102,-1.447 0.864,-1.497l43.892,0c0.044,0.003 0.087,0.006 0.13,0.009c0.419,0.083 0.516,0.192 0.662,0.382c0.438,0.571 0.043,1.55 -0.792,1.604l-40.9,0l0,4.988c-0.023,0.979 -1.96,1.461 -1.995,0l0,-4.988Zm27.932,-8.038c0,-0.987 0.018,-1.974 -0.001,-2.961c-0.016,-0.512 -0.467,-0.961 -0.984,-0.971c-3.993,-0.025 -7.987,0 -11.98,0c-0.525,0.003 -0.998,0.454 -1.001,1l0,2.912c4.655,-0.003 9.311,0.006 13.966,0.02Z" />
									</svg>
								</li>
								<li>
									<div class="input-group  ">
										<div class="input-group-prepend">
											<button style="min-width: 2.5rem"
												class="btn-custom-btn btn btn-decrement btn-outline-secondary"
												type="button" (click)="change('d','l')">
												<strong>-</strong>
											</button>
										</div>
										<input type="number" [value]="quotebot_form.no_of_luggage" min="0" max="2000"
											step="1" inputmode="decimal" style="text-align: center"
											class="form-control " placeholder="">
										<div class="input-group-append">
											<button style="min-width: 2.5rem"
												class="btn-custom-btn btn btn-increment btn-outline-secondary"
												type="button" (click)="change('i','l')">
												<strong>+</strong>
											</button>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer pt-0">
				<button class="custom-btn-custom-btn btn savebutton" data-dismiss="modal"
					(click)="fetchMasterVehicles()" tabindex="1">SAVE</button>
			</div>
		</div>
	</div>
</div>

<!-- send email and call modal -->
<div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
	aria-hidden="true" data-keyboard="false" #sendEmailModalFocus>
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content" style="background: #f5f4fe;">
			<div class="modal-header pb-0">

			</div>
			<button type="button" (click)="closeModal()" class="close closeButton x-circle-btn m-2" data-dismiss="modal"
				aria-label="Close">
				<i class="bi bi-x-circle"></i>
			</button>
			<hr>
			<div class="text-center iconStyle">
				<div class="row">
					<div class="col-md-12">
						<h5 class="mb-5">
							Connect with {{ passengerDetails?.affiliate_name }} Via</h5>
					</div>
					<div class="d-flex" style="width: -webkit-fill-available;">
						<div class="col-md-6" (click)="messageField('Phone')">

							<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 122.88"
								style="enable-background:new 0 0 122.88 122.88" xml:space="preserve">
								<style type="text/css">
									.step0 {
										fill: #1A73E8;
									}

									.step1 {
										fill: #185ABC;
									}

									.step2 {
										fill: #FFFFFF;
									}

									.step3 {
										fill: #8AB4F8;
									}
								</style>
								<g>
									<path class="step0"
										d="M122.88,61.41C122.88,27.49,95.39,0,61.47,0S0.06,27.49,0.06,61.41s27.49,61.41,61.41,61.41 S122.88,95.32,122.88,61.41L122.88,61.41z" />
									<path class="step1"
										d="M83.82,87.93H41.97c-5.95,0-11.14-4.49-11.14-10.45v-0.06v3.48c0,5.95,5.19,11.14,11.14,11.14h41.85 c6.01,0,11.14-5.25,11.14-11.14v-3.48C94.96,83.38,89.77,87.93,83.82,87.93L83.82,87.93L83.82,87.93z M30.77,57.93v-4.75 l-7.79-12.85c-0.44-0.7-0.63-1.33-0.57-1.96v5.44c0,0.51,0.19,1.01,0.51,1.58L30.77,57.93L30.77,57.93z" />
									<path class="step2"
										d="M83.82,36.28H25.2c-2.34,0-3.67,2.03-2.09,4.18l7.66,13.29v23.74c0,6.08,4.43,11.14,10.45,11.14h42.54 c6.01,0,11.14-5.13,11.14-11.14V47.48C94.96,41.4,89.83,36.28,83.82,36.28L83.82,36.28z" />
									<path class="step3"
										d="M83.12,53.05H42.67c-1.77,0-3.48-1.08-3.48-2.79c0-1.71,1.71-2.79,3.48-2.79h40.45c1.77,0,3.48,1.08,3.48,2.79 C86.6,51.98,84.9,53.05,83.12,53.05L83.12,53.05L83.12,53.05z M83.12,64.19H42.67c-1.77,0-3.48-1.08-3.48-2.79 c0-1.71,1.71-2.79,3.48-2.79h40.45c1.77,0,3.48,1.08,3.48,2.79C86.6,63.12,84.9,64.19,83.12,64.19L83.12,64.19L83.12,64.19z M71.92,75.4H42.61c-1.77,0-3.48-1.08-3.48-2.79c0-1.71,1.71-2.79,3.48-2.79h29.31c1.77,0,3.48,1.08,3.48,2.79 C75.4,74.32,73.69,75.4,71.92,75.4L71.92,75.4z" />
									<path class="step1"
										d="M122.88,61.09c-0.13,26.91-17.66,49.76-41.85,57.86c-6.14,2.09-12.72,3.17-19.56,3.17 c-33.81,0-61.22-27.29-61.41-61.03v0.38c0,33.93,27.48,61.41,61.41,61.41c6.84,0,13.42-1.14,19.56-3.17 c24.31-8.17,41.85-31.15,41.85-58.24V61.09L122.88,61.09z" />
									<path class="step3"
										d="M80.97,3.17C74.83,1.08,68.25,0,61.41,0C27.48,0,0,27.48,0,61.41v0.38C0.19,28.04,27.6,0.76,61.41,0.76 c6.84,0,13.42,1.14,19.56,3.17c24.25,8.1,41.72,30.96,41.85,57.86v-0.38C122.88,34.31,105.34,11.33,80.97,3.17L80.97,3.17z" />
								</g>
							</svg>
							<h6 class="headingStyle">Send SMS</h6>
						</div>
						<div class="col-md-6">
							<a href="tel:{{passengerDetails?.affiliate_phone}}">
								<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 122.88 122.88">
									<defs>
										<style>
											.phoneCls-1 {
												fill: #33a867;
												fill-rule: evenodd;
											}
										</style>
									</defs>
									<path class="phoneCls-1"
										d="M61.44,0A61.46,61.46,0,1,1,18,18,61.21,61.21,0,0,1,61.44,0ZM48.1,56.19A49.35,49.35,0,0,0,55.39,66.4a41.25,41.25,0,0,0,11.55,8.51,1.14,1.14,0,0,0,1,0,4.48,4.48,0,0,0,1.39-1A16.91,16.91,0,0,0,70.6,72.4c1.85-2.44,4.15-5.47,7.39-4l.2.11L89,74.77l.11.07a4.84,4.84,0,0,1,2,4.2,11.43,11.43,0,0,1-1.58,5.37,11.06,11.06,0,0,1-5.2,4.6A23.63,23.63,0,0,1,78,90.75a21.69,21.69,0,0,1-9.8-.82,43.06,43.06,0,0,1-9.85-4.76L58.05,85c-1.6-1-3.33-2.07-5-3.33a62.69,62.69,0,0,1-16.65-18.9c-3.46-6.26-5.34-13-4.32-19.44a14,14,0,0,1,4.72-8.85A12.68,12.68,0,0,1,46.18,32a1.35,1.35,0,0,1,1.09.7L54.21,44.4a3.68,3.68,0,0,1,.59,3.92,7.71,7.71,0,0,1-2.66,3c-.37.32-.82.64-1.29,1-1.55,1.12-3.32,2.42-2.71,4l0-.05Z" />
								</svg>
								<h6 class="headingStyle">Make Call</h6>
							</a>


						</div>
					</div>

				</div>
				<div class="row" [hidden]="!show">
					<div class="col-md-12">
						<mat-form-field class="example-full-width" appearance="outline">
							<mat-label>
								Send SMS
							</mat-label>
							<textarea matInput id="myTextarea" placeholder="Write a Message...." id="clearField"
								cdkTextareaAutosize cdkAutosizeMaxRows="10" #autosize="cdkTextareaAutosize" #inputmsg
								(focus)="$event.target.select()" style="font-weight: 700;"></textarea>
						</mat-form-field>
						<div class="d-flex justify-content-center mt-2">
							<!-- <button type="button" data-dismiss="modal" (click)="closeModal()"
								class="btn btnStyle">Close</button> -->
							<button class="btn saveButtonModal" (click)="submit(inputmsg.value,passengerDetails)"
								data-dismiss="modal">Send</button>
						</div>
					</div>
				</div>
			</div>

		</div>

	</div>
</div>

<!-- Notification Modal -->
<div class="modal fade" id="notificationModal" tabindex="-1" role="dialog" aria-labelledby="notificationModal"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close closeButton" data-dismiss="notificationModal" aria-label="Close"
				id="closeModal">
				<span aria-hidden="true">&times;</span>
			</button>
			<div class="modal-body">
				<h5 class="text-center">{{notification_msg}}</h5>
			</div>
			<div class="modal-footer py-0" style="justify-content: center !important;">
				<button type="button" class="custom-btn button2" data-dismiss="notificationModal"
					id="closeModal1">OK</button>
			</div>
		</div>
	</div>
</div>

<app-scroll-to-top></app-scroll-to-top>