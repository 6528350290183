<ngx-spinner></ngx-spinner>
<div class="innerpage_wrapper">
	<div class="innerpage_topbanner">
		<h2>
			Vehicle Choices
			<hr>
		</h2>
	</div>
	<section class="safety_wrapper">
		<div class="container" *ngFor="let fleet of fleetContents">
			<h3>{{fleet?.title}}</h3>
			<p [innerHTML]="fleet?.content"></p>
		</div>
	</section>
	<!----OUR CLIENTS------------->
	<section class="client_wrapper client_wrapper_desktop">
		<div class="container">
			<h2>SOME OF OUR CLIENTS
			</h2>
			<hr>
			<div class="row logo_image">
				<div class="col-2 col-md-2">
					<img src="assets/images/client8.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client9.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client12.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client17.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client19.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client2.svg" alt="client1">
				</div>
			</div>
			<div class="row logo_image">
				<div class="col-2 col-md-2">
					<img src="assets/images/client1.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client21.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client3.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client5.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client4.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client18.png" alt="client1">
				</div>
			</div>
			<div class="row logo_image">
				<div class="col-2 col-md-2">
					<img src="assets/images/client20.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client16.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client6.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client10.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client15.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client13.jpg" alt="client1">
				</div>
			</div>
			<div class="row logo_image">
				<div class="col-3 col-md-3"></div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client14.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client11.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client7.png" alt="client1">
				</div>
				<div class="col-3 col-md-3"></div>
			</div>

		</div>
	</section>

	<!-- OUR CLIENTS FOR MOBILE -->
	<section class="client_wrapper client_wrapper_mobile">
		<div class="container">
			<h2>SOME OF OUR CLIENTS
			</h2>
			<hr>
			<div class="client_logo owl-carousel owl-theme">
				<div class="item">
					<img src="assets/images/client8.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client9.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client13.jpg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client2.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client17.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client19.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client1.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client21.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client3.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client5.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client4.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client18.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client20.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client16.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client6.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client10.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client15.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client12.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client14.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client11.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client7.png" alt="client1">
				</div>
			</div>
		</div>
	</section>
</div>